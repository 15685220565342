import axios from "axios";
import { urlsBase } from "../../../../config";

export const PROVIDERS_URL = `${urlsBase.base_url}/api/v1/business-link/providers`;
export const PROVIDER_ENABLE_URL = `${urlsBase.base_url}/api/v1/business-link/providers/{company_id}/enable`;
export const PROVIDER_DISABLE_URL = `${urlsBase.base_url}/api/v1/business-link/providers/{company_id}/disable`;
export const PROVIDER_BLOCK_URL = `${urlsBase.base_url}/api/v1/business-link/providers/{company_id}/block`;
export const PROVIDER_UNBLOCK_URL = `${urlsBase.base_url}/api/v1/business-link/providers/{company_id}/unblock`;


export function listProviders() {
  return axios.get(`${PROVIDERS_URL}`);
}

export function enableProvider({ company_id }) {
  let enable_url;
  enable_url = PROVIDER_ENABLE_URL.replace("{company_id}",company_id);
  return axios.put(enable_url);
}

export function disableProvider({ company_id }) {
  let disable_url;
  disable_url = PROVIDER_DISABLE_URL.replace("{company_id}",company_id);
  return axios.put(disable_url);
}

export function blockProvider({ company_id }) {
  let block_url;
  block_url = PROVIDER_BLOCK_URL.replace("{company_id}",company_id);
  return axios.put(block_url);
}

export function unblockProvider({ company_id }) {
  let unblock_url;
  unblock_url = PROVIDER_UNBLOCK_URL.replace("{company_id}",company_id);
  return axios.put(unblock_url);
}
