import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import delivery from "../../design-system/icons/Shapes/delivery.svg";

export const ShippingCostBadge = ({ currency, shippingCost, ...props }) => {
  const parsedShippingCost = parseInt(shippingCost) === 0;
  return (
    <Box
      color={"brand.success"}
      fontSize="16px"
      lineHeight={"22px"}
      padding="4px"
      px="8px"
      borderRadius={"12px"}
      backgroundColor="rgba(76, 175, 80, 0.1)"
      width={"fit-content"}
      {...props}
    >
      <Flex alignItems={"center"} gap={1}>
        <BekeuIcon src={delivery} />
        {!shippingCost || parseInt(shippingCost) === 0 ? (
          <Text fontWeight={400} color="brand.success">
            Envío Incluído
          </Text>
        ) : (
          <>
            <Text fontWeight={300}>Envío por</Text>
            {currency ? (
              <Text fontWeight={600}>{`${currency} ${shippingCost}`}</Text>
            ) : (
              <Text fontWeight={600}>${shippingCost}</Text>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};
