import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listProviders,
  enableProvider,
  disableProvider,
  blockProvider,
  unblockProvider,
} from "../cruds/ClientWhiteList.crud";

const actionTypes = {
  LIST_PROVIDERS: "LIST_PROVIDERS",
  LIST_PROVIDERS_SUCCESS: "LIST_PROVIDERS_SUCCESS",
  LIST_PROVIDERS_ERROR: "LIST_PROVIDERS_ERROR",
  ENABLE_PROVIDER: "ENABLE_PROVIDER",
  ENABLE_PROVIDER_SUCCESS: "ENABLE_PROVIDER_SUCCESS",
  ENABLE_PROVIDER_ERROR: "ENABLE_PROVIDER_ERROR",
  DISABLE_PROVIDER: "DISABLE_PROVIDER",
  DISABLE_PROVIDER_SUCCESS: "DISABLE_PROVIDER_SUCCESS",
  DISABLE_PROVIDER_ERROR: "DISABLE_PROVIDER_ERROR",
  BLOCK_PROVIDER: "BLOCK_PROVIDER",
  BLOCK_PROVIDER_SUCCESS: "BLOCK_PROVIDER_SUCCESS",
  BLOCK_PROVIDER_ERROR: "BLOCK_PROVIDER_ERROR",
  UNBLOCK_PROVIDER: "BLOCK_PROVIDER",
  UNBLOCK_PROVIDER_SUCCESS: "BLOCK_PROVIDER_SUCCESS",
  UNBLOCK_PROVIDER_ERROR: "BLOCK_PROVIDER_ERROR",
};

const initialState = {
  providers: [],
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["asd"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_PROVIDERS:
      case actionTypes.ENABLE_PROVIDER:
      case actionTypes.DISABLE_PROVIDER:
      case actionTypes.BLOCK_PROVIDER:
      case actionTypes.UNBLOCK_PROVIDER:{
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_PROVIDERS_SUCCESS:{
        return {
          ...state,
          providers: action.payload,
          loading: false,
        };
      }
      case actionTypes.ENABLE_PROVIDER_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.DISABLE_PROVIDER_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.BLOCK_PROVIDER_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.UNBLOCK_PROVIDER_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.LIST_PROVIDERS_ERROR:
      case actionTypes.ENABLE_PROVIDER_ERROR:
      case actionTypes.DISABLE_PROVIDER_ERROR:
      case actionTypes.BLOCK_PROVIDER_ERROR:{
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  listProviders: (value) => ({
    type: actionTypes.LIST_PROVIDERS,
    payload: value,
  }),
  fillProvidersResult: (value) => ({
    type: actionTypes.LIST_PROVIDERS_SUCCESS,
    payload: value,
  }),
  enableProvider: (value) => ({
    type: actionTypes.ENABLE_PROVIDER,
    payload: value,
  }),
  disableProvider: (value) => ({
    type: actionTypes.DISABLE_PROVIDER,
    payload: value,
  }),
  blockProvider: (value) => ({
    type: actionTypes.BLOCK_PROVIDER,
    payload: value,
  }),
  unblockProvider: (value) => ({
    type: actionTypes.UNBLOCK_PROVIDER,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_PROVIDERS, function* listProvidersSaga({ payload }) {
    try {
      const response = yield listProviders(payload);
      const data = response.data.message;

      if (response.status === 200) {
        yield put({ type: actionTypes.LIST_PROVIDERS_SUCCESS, payload: data });
      } else {
        yield put({ type: actionTypes.LIST_PROVIDERS_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.LIST_PROVIDERS_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.ENABLE_PROVIDER, function* enableProviderSaga({ payload }) {
    try {
      const response = yield enableProvider(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.ENABLE_PROVIDER_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.ENABLE_PROVIDER_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.ENABLE_PROVIDER_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.DISABLE_PROVIDER, function* disableProviderSaga({ payload }) {
    try {
      const response = yield disableProvider(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.DISABLE_PROVIDER_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.DISABLE_PROVIDER_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.DISABLE_PROVIDER_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.BLOCK_PROVIDER, function* blockProviderSaga({ payload }) {
    try {
      const response = yield blockProvider(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.BLOCK_PROVIDER_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.BLOCK_PROVIDER_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.BLOCK_PROVIDER_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.UNBLOCK_PROVIDER, function* unblockProviderSaga({ payload }) {
    try {
      const response = yield unblockProvider(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.UNBLOCK_PROVIDER_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.UNBLOCK_PROVIDER_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.UNBLOCK_PROVIDER_ERROR, error: err });
    }
  });
}