import React, { useEffect, useState } from "react";
import {
  Divider,
  Flex,
  SkeletonText,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { injectIntl } from "react-intl";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { connect } from "react-redux";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ErrorModal } from "../ErrorModal/ErrorModal";

const ItemOptions = ({ detail, onCartAddition, handleBuyNow }) => {
  const mainState = useSelector((state) => state);
  const history = useHistory();
  const [buyNowResult, setBuyNowResult] = useState(false);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasError = `${mainState.cart.result}`;

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    buyNowResult &&
      mainState.cart.result &&
      !hasError.includes("error") &&
      history.push("/cart");

    if (mainState.cart.result && hasError.includes("error")) {
      setBuyNowResult(false);
      onOpen();
      setLoading(false);
    }
  }, [mainState.cart.result]);

  useEffect(() => {
    if (mainState.cart.result) {
      setResult(false);
      setLoading(false);
      handleScrollTo();
      setCartLoading(false);
    }
  }, [mainState.cart.result]);

  return (
    <Flex flexDir={"column"} minH="250px" gap="24px">
      <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />

      <Divider w={"100%"} />
      <Text fontSize={"16px"} fontWeight={600} px="24px">
        {detail?.currency_code} {detail?.value} + Imp
      </Text>
      <Flex flexDir={"column"} px="24px">
        <ShippingCostBadge
          currency={detail?.currency_code}
          shippingCost={detail?.shipping_cost}
        />
        <Flex>
          <Text>Tiempo máximo de entrega </Text>
          <Text ml={1} fontWeight={600}>
            {detail?.sp_delivery_time} días
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} px="24px">
        <Flex alignItems={"center"}>
          <Text>Vendido por⠀</Text>
          <ProductDetailModalSeller
            sellerName={detail?.provider_fantasy_name}
            email={detail?.provider_email}
            phone={detail?.provider_phone}
          />
        </Flex>
      </Flex>
      <Flex gap={2} alignItems="center" justifyContent={"center"} px="24px">
        <ButtonBekeuM
          my={0}
          w="100%"
          onClick={() => {
            handleBuyNow(detail);
            setBuyNowResult(!buyNowResult);
            setLoading(true);
          }}
          isLoading={loading}
        >
          Comprar ahora
        </ButtonBekeuM>
        <ButtonBekeuOutline
          height={"38px"}
          fontSize="16px"
          onClick={() => {
            setResult(true);
            onCartAddition(detail);
            setCartLoading(true);
          }}
          isLoading={cartLoading}
        >
          Agregar al carrito
        </ButtonBekeuOutline>
      </Flex>
    </Flex>
  );
};

const ProductDetailOptions = ({
  providers,
  addItemToCart,
  cartBuyNow,
  skeletonLoading,
  ...props
}) => {
  const handleCartAddition = (item) => {
    addItemToCart(item);
  };

  const handleBuyNow = (item) => {
    cartBuyNow(item);
  };

  return (
    <>
      {providers && providers.length > 0 && (
        <BoxBekeu
          p={0}
          w={"50%"}
          visibility={providers.length > 1 ? "inherit" : "hidden"}
          {...props}
        >
          <Flex flexDir={"column"}>
            <Text px="24px" py="16px">
              Otras opciones de compra
            </Text>
            {skeletonLoading ? (
              <Flex flexDir={"column"} justifyContent={"center"}>
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"40%"}
                  mt={"8"}
                />
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"35%"}
                  mt={"8"}
                />
                <SkeletonText
                  noOfLines={2}
                  spacing="4"
                  width={"50%"}
                  mt={"8"}
                />
                <SkeletonText mt="4" noOfLines={2} spacing="4" width={"70%"} />
              </Flex>
            ) : (
              providers
                .slice(1)
                .map((detail) => (
                  <ItemOptions
                    detail={detail}
                    onCartAddition={handleCartAddition}
                    handleBuyNow={handleBuyNow}
                  />
                ))
            )}
          </Flex>
        </BoxBekeu>
      )}
    </>
  );
};

export default injectIntl(connect(null, cartActions)(ProductDetailOptions));
