import { Divider, Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import React from "react";
import info from "../../design-system/icons/Shapes/info-circle-dark.svg";
import box from "../../design-system/icons/Essentials/box.svg";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const ProviderText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={600}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <BekeuIcon src={info} ml="10px" ref={ref} {...rest} />
));

export const ProviderTitle = ({ item, infobtn }) => {
  return (
    <>
      <Flex
        w="100%"
        py="16px"
        alignItems={"center"}
        justifyContent="space-between"
        gap="12px"
      >
        <ProviderText w="6%" textAlign="center">
        </ProviderText>
        <ProviderText w="6%" textAlign="center">
          {item?.enable_client}
        </ProviderText>
        <ProviderText w="8%" textAlign="center">
          {item?.client_block}
        </ProviderText>
        <ProviderText w="20%" textAlign="center">
          {item?.business_name}
        </ProviderText>
        {/*<ProviderText w="10%" textAlign={"center"} title={item?.alias}>
          {item?.alias}
        </ProviderText>*/}
        <ProviderText w="10%" textAlign={"center"}>
          {item?.internal_code}
        </ProviderText>
        <ProviderText w="8%" textAlign={"center"}>
          {item?.state}
        </ProviderText>
        {/*<Tooltip
          closeDelay={200}
          label={""}
          bg="white"
          color="black"
          borderRadius={"12px"}
          w={"85%"}
          p={"12px"}
          borderWidth={"1px"}
          borderTopLeftRadius={"0px"}
          marginLeft="100px"
          placement={"bottom-start"}
          overflow="hidden"
        >
          <Flex alignItems={"center"} w={"10%"} isTruncated>
            <Text
              fontWeight={600}
              fontSize={"14px"}
              isTruncated
              lineHeight={"19px"}
              fontStyle="normal"
            >
              {item?.alias}
            </Text>
            <CustomCard />
          </Flex>
        </Tooltip>*/}
        {/*<ClientText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.alias}
          </Flex>
        </ClientText>*/}
        {/*<Box>{infobtn}</Box>*/}
        <ProviderText w="6%" textAlign="center">
        </ProviderText>
      </Flex>
      <Divider />
    </>
  );
};
